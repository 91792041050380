import { createSelector } from '@ngrx/store';

import { IState } from '../index';
import { IPlayAudioState, playAudioFeatureKey } from './play-audio.reducer';

// Selector State
export const getPlayAudioState = (state: IState): IPlayAudioState => state[playAudioFeatureKey];

// Callbacks selectors
export const selectPlayAudio = (state: IPlayAudioState): boolean => state.state;

// Selector Data
export const getPlayAudio = createSelector(getPlayAudioState, selectPlayAudio);
