import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private eventSubject$ = new BehaviorSubject<any>(EventData);
  eventChanged$ = this.eventSubject$.asObservable();

  emitError(event: EventData) {
    this.eventSubject$.next(event);
  }
}

export class EventData {
  name: string;
  value: any;

  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
