import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Device } from "@opentok/client";
import { Observable } from "rxjs";

import * as OT from '@opentok/client';

// Store
import { ToggleLoaderStateAction } from '../reducers/loader/loader.actions';

// Services
import { UserCallsService } from "@services/user-calls.service";

// Models
import { environment } from '@environments/environment';

export interface IDevicesData {
  audioInput: Device[],
  audioOutput: Device[],
  videoInput: Device[]
}

@Injectable()
export class OpentokService {

  private session: OT.Session;

  constructor(
    private store: Store,
    private userCallService: UserCallsService
  ) {
  }

  initSession(sessionId) {
    this.session = OT.initSession(
      environment.api_key,
      sessionId
    );
    return new Promise((resolve) => {
      resolve(this.session);
    });
  }

  checkSystemRequirements() {
    return new Promise((resolve) => {
      let a = OT.checkSystemRequirements();
      resolve(a);
    });
  }

  getMediaDevises(): Observable<IDevicesData> {
    return new Observable<IDevicesData>(observer => {
      OT.getDevices((error, devices) => {
        if (error) {
          this.store.dispatch(new ToggleLoaderStateAction(false));
          this.userCallService.sendErrorCall(error);
          return;
        }
        this.userCallService.sendErrorCall({
          page: 'devices-settings',
          devices
        });

        const audioInputDevicesList = devices.filter((device) => {
          return device.kind.toLowerCase().includes('audioinput')
        });

        const videoDevicesList = devices.filter((device) => {
          return device.kind.toLowerCase().includes('videoinput')
        });

        const audioOutputDevicesList = devices.filter((device) => {
          return device.kind.toLowerCase().includes('audiooutput')
        });

        observer.next({
          audioInput: audioInputDevicesList,
          audioOutput: audioOutputDevicesList,
          videoInput: videoDevicesList
        });
        observer.complete();
      });
    })
  }
}
