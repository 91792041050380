import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

// Store
import {
  paymentsActionsType,
  LoadPaymentCardsSuccessAction,
  LoadPaymentCardsErrorAction,
  DeleteCardSuccessAction,
  DeleteCardErrorAction,
} from './payments.actions';
import { ToggleLoaderStateAction } from '../loader/loader.actions';

// Services
import { SystemNotificationsService } from '@services/system-notifications.service';
import { UserCallsService } from '@services/user-calls.service';

@Injectable()
export class PaymentsEffects {

  constructor(
    private actions$: Actions,
    private store$: Store,
    private router: Router,
    private systemNotificationsService: SystemNotificationsService,
    private userCallService: UserCallsService,
  ) {
  }

  loadPayments$ = createEffect(() => this.actions$.pipe(
    ofType(paymentsActionsType.loadPaymentCards),
    switchMap(() => {
      return this.userCallService.userGetCards()
        .pipe(
          map((cards) => {
            return new LoadPaymentCardsSuccessAction(cards);
          }),
          catchError((error) => {
            console.log('loadPayments$ error', error);
            this.userCallService.sendErrorCall(error);
            return of(new LoadPaymentCardsErrorAction(error));
          })
        );
    })
  ));

  deleteCard$ = createEffect(() => this.actions$.pipe(
    ofType(paymentsActionsType.deleteCard),
    switchMap(({ cardId }) => {
      this.store$.dispatch(new ToggleLoaderStateAction(true));
      return this.userCallService.deleteUserCard(cardId)
        .pipe(
          map((card) => {
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            this.systemNotificationsService.openSuccessSnackBar('The payment card has been successfully deleted!');
            return new DeleteCardSuccessAction(cardId);
          }),
          catchError((error) => {
            console.log('deleteCard$ error', error);
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            this.systemNotificationsService.openErrorSnackBar('Failed to delete payment card, refresh the page and try again!');
            this.userCallService.sendErrorCall(error);
            return of(new DeleteCardErrorAction(error));
          })
        );
    })
  ));
}
