export enum STORAGE_ITEMS {
  CATEGORY = 'category',
  PREV_CATEGORY = 'prev_category',
  CURR_CATEGORY = 'curr_category',
  PREV_CALL_ID = 'prev_call_id',
  UDID = 'device_UDID',
  TEMPORARY_TOKEN = 'temporary_token',
  WORKER_ID = 'worker_id',
  CURR_WORKER_ID = 'curr_worker_id',
  ROLE = 'role',
  IS_CARDS = 'iCards',
  COUNTRY = 'country',
  TOKEN = 'token',
  TENANT_EMAIL = 'tenant-email',
  ID = 'user_id',
  WORKER_DATA = 'workerData',
  TB_DATA = 'tb_data',
  CALL_ID = 'call_id',
  UnloadCall = 'unload-call',
  PHOTO = 'photo',
  USER = 'user',
  USER_ID = 'user_id',
  PHOTO_CROP = 'photo_crop',
  WIDGET_DATA = 'widget_data',
  WIDGET_ID = 'widget_id',
  LINK_TYPE = 'link_type',
  AudioDevice = 'audiodevice',
  AudioDeviceLabel = 'audiodevice-label',
  VideoDevice = 'videodevice',
  VideoDeviceLabel = 'videodevice-label'
}

export enum ESessionStorageKeys {
  TermsAccepted = 'terms-accepted'
}

export enum SCREEN_SIZE {
  DESKTOP = 'desktop',
  TABLET = 'tablet'
}

export const TEMPORARY = 'temporary'

export const USER = 'user'
