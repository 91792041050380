import { STORAGE_ITEMS, TEMPORARY } from "@consts/storage";
import { ROLES } from "@consts/roles";

export class RoleHelper {

  public static isTemporary(): boolean {
    return localStorage.getItem(STORAGE_ITEMS.ROLE) == TEMPORARY;
  }

  public static isUsualUser(): boolean {
    return localStorage.getItem(STORAGE_ITEMS.ROLE) == ROLES.USER;
  }

  public static isTenant(): boolean {
    return localStorage.getItem(STORAGE_ITEMS.ROLE) == ROLES.TENANT;
  }
}
