import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

// Store
import {
  callHistoryActionsType,
  LoadCallHistoryErrorAction,
  LoadCallHistorySuccessAction
} from './call-history.actions';
import { ToggleLoaderStateAction } from '../loader/loader.actions';

// Services
import { SystemNotificationsService } from '@services/system-notifications.service';
import { UserCallsService } from '@services/user-calls.service';

@Injectable()
export class CallHistoryEffects {

  constructor(
    private actions$: Actions,
    private store$: Store,
    private router: Router,
    private systemNotificationsService: SystemNotificationsService,
    private userCallService: UserCallsService,
  ) {
  }

  loadCallHistory$ = createEffect(() => this.actions$.pipe(
    ofType(callHistoryActionsType.loadCallHistory),
    switchMap(({ page, isLoadMore, limit }) => {
      this.store$.dispatch(new ToggleLoaderStateAction(true));
      return this.userCallService.userCalls({ page, limit })
        .pipe(
          map((chatHistory) => {
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            return new LoadCallHistorySuccessAction(chatHistory, isLoadMore);
          }),
          catchError((error) => {
            console.log('loadCallHistory$', error);
            this.store$.dispatch(new ToggleLoaderStateAction(false));
            this.userCallService.sendErrorCall(error);
            return of(new LoadCallHistoryErrorAction(error));
          })
        );
    })
  ));
}
