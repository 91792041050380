import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { NotificationTypes } from '@consts/global';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit, AfterViewInit {
  public readonly ENotificationTypes = NotificationTypes;
  @ViewChild('audioElement', { static: false }) audioElement: ElementRef<HTMLAudioElement>;
  public type: string = '';
  public message: string = '';
  public callId: number | null = null;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { type: string, message: string, callId?: number },
    private snackBarRef: MatSnackBarRef<SnackBarComponent>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.type = this.data.type;
    this.message = this.data.message;
    this.callId = this.data?.callId ? this.data.callId : null;
  }

  ngAfterViewInit(): void {
    this.checkCallId();
  }

  checkCallId(): void {
    if (!this.callId) return;
    this.playAudio();
  }

  openChatWithEngineer(): void {
    if (!this.data.callId) return;
    this.router.navigate(['/call/chat-history'], {
      queryParams: {
        call_id: this.callId
      }
    });
    this.closeShackBar();
  }

  playAudio(): void {
    const audioElement: HTMLAudioElement = this.audioElement?.nativeElement;
    if (!audioElement) return;
    audioElement?.load();
    audioElement?.play()
      .catch(error => {
        console.log('playAudio', error);
      });
  }

  closeShackBar(): void {
    this.snackBarRef.dismiss();
  }
}
