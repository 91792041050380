import { Injectable } from '@angular/core';
import { SpeedTestService } from 'ng-speed-test';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { CSpeedConnectionParameters, ICheckInternetConnectionData, INTERNET_CONNECTION } from "@consts/global";

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {

  constructor(private speedTestService: SpeedTestService) {
  }

  checkInternetConnection(): Observable<ICheckInternetConnectionData> {
    return this.speedTestService.getMbps(CSpeedConnectionParameters)
      .pipe(
        map(speed => {
          const transformSpeed = +speed.toFixed(2);
          if (transformSpeed <= 1) {
            return { status: INTERNET_CONNECTION.LOW, speed: transformSpeed };
          }
          if (speed > 1 && speed <= 3) {
            return { status: INTERNET_CONNECTION.MEDIUM_LOW, speed: transformSpeed };
          }
          if (speed > 3 && speed < 10) {
            return { status: INTERNET_CONNECTION.MEDIUM, speed: transformSpeed };
          }
          return { status: INTERNET_CONNECTION.GOOD, speed: transformSpeed };
        })
      )
  }
}
