import { Action } from '@ngrx/store';

export enum loaderActionsType {
  toggleLoaderState = '[Loader] Toggle loader state',
}

export class ToggleLoaderStateAction implements Action {
  readonly type = loaderActionsType.toggleLoaderState;

  constructor(public state: boolean) {
  }
}
export type LoaderActions = ToggleLoaderStateAction;
