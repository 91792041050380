import { Action } from '@ngrx/store';
import { ILanguage } from '@interfaces/languages.interfaces';

export enum languagesActionsType {
  loadLanguagesList = '[Languages] load languages list',
  loadLanguagesListSuccess = '[Languages] load languages list success',
  loadLanguagesListError = '[Languages] load languages list error',
}

export class LoadLanguagesListAction implements Action {
  readonly type = languagesActionsType.loadLanguagesList;
}

export class LoadLanguagesListSuccessAction implements Action {
  readonly type = languagesActionsType.loadLanguagesListSuccess;

  constructor(public languages: ILanguage[]) {
  }
}

export class LoadLanguagesListErrorAction implements Action {
  readonly type = languagesActionsType.loadLanguagesListError;

  constructor(public error: any) {
  }
}

export type LanguagesActions = LoadLanguagesListAction |
  LoadLanguagesListSuccessAction |
  LoadLanguagesListErrorAction;
