import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

import { IWidgetsData } from "@interfaces/widgets.interface";
import { STORAGE_ITEMS } from "@consts/storage";

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  public firstLoadWidget$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public widgetId: number = this.getWidgetId();

  constructor(private http: HttpClient) {
  }

  getWidgetDataFromServer(id: number): Observable<IWidgetsData> {
    return this.http.get<IWidgetsData>(`/widgets/${ id }`);
  }

  getWidgetId(): number {
    const path = window.location.pathname.split('/');
    let widgetId = path[2];
    if (isNaN(+widgetId)) {
      widgetId = sessionStorage.getItem(STORAGE_ITEMS.WIDGET_ID);
    }
    return Number(widgetId ? widgetId : {});
  }

  getWidgetStatus(): boolean {
    const hasParent = window !== window.parent;
    const isFirstLoadWidget = this.firstLoadWidget$.getValue();
    const isWidgetId = !!sessionStorage.getItem(STORAGE_ITEMS.WIDGET_ID);
    return (hasParent && isFirstLoadWidget) || (hasParent && isWidgetId);
  }
}
