import { LoaderActions, loaderActionsType } from './loader.actions';

export const loaderFeatureKey = 'loader';

export interface ILoaderState {
  state: boolean,
}

export const initialState: ILoaderState = {
  state: false
};

export const loaderReducer = (state = initialState, action: LoaderActions) => {
  switch (action.type) {
    case loaderActionsType.toggleLoaderState:
      return {
        state: action.state
      };
    default:
      return state;
  }
};
