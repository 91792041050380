import { Action } from '@ngrx/store';
import { ICategoriesResp } from '@interfaces/categories.interface';

export enum servicesActionsType {
  loadServicesState = '[Services] Load services list',
  loadServicesStateSuccess = '[Services] Load services list success',
  loadServicesStateError = '[Services] Load services list error',
}

export class LoadServicesStateAction implements Action {
  readonly type = servicesActionsType.loadServicesState;
}

export class LoadServicesStateActionSuccess implements Action {
  readonly type = servicesActionsType.loadServicesStateSuccess;

  constructor(public services: ICategoriesResp) {
  }
}

export class LoadServicesStateActionError implements Action {
  readonly type = servicesActionsType.loadServicesStateError;

  constructor(public error: any) {
  }
}

export type ServicesActions = LoadServicesStateAction | LoadServicesStateActionSuccess | LoadServicesStateActionError;
