import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthorizedGuard } from "@guards/authorized.guard";
import { IframeGuard } from "@guards/iframe.guard";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthorizedGuard],
    loadChildren: () => import('./existing-user/existing-user.module').then(m => m.ExistingUserModule)
  },
  {
    path: 'device-permissions',
    loadChildren: () => import('./device-permissions/device-permissions.module').then(m => m.DevicePermissionsModule)
  },
  {
    path: 'landing',
    canActivate: [AuthorizedGuard, IframeGuard],
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'privacy',
    canActivate: [IframeGuard],
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'terms',
    canActivate: [IframeGuard],
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'deeplink/:id',
    loadChildren: () => import('./magic-link/magic-link.module').then(m => m.MagicLinkModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonPagesRoutingModule {
}
