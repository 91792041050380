import { Action } from '@ngrx/store';

export enum playAudioActionsType {
  setPlayAudioState = '[PLayAudio] Set pLay audio state',
}

export class SetPlayAudioStateAction implements Action {
  readonly type = playAudioActionsType.setPlayAudioState;

  constructor(public state: boolean) {
  }
}
export type PlayAudioActions = SetPlayAudioStateAction;
