import { PaymentsActions, paymentsActionsType } from './payments.actions';
import { ICards } from '@interfaces/user.interface';

export const paymentsFeatureKey = 'payments';

export interface IPaymentsState {
  cards: ICards | null,
  isFetching: boolean,
  isError: boolean
}

export const initialState: IPaymentsState = {
  cards: null,
  isFetching: false,
  isError: false
};

export const paymentsReducer = (state = initialState, action: PaymentsActions) => {
  switch (action.type) {
    case paymentsActionsType.clearPaymentCards:
      return {
        cards: null,
        isFetching: false,
        isError: false
      };
    case paymentsActionsType.loadPaymentCards:
      return {
        ...state,
        isError: false,
        isFetching: true,
      };
    case paymentsActionsType.loadPaymentCardsSuccess:
      return {
        cards: action.cards,
        isError: false,
        isFetching: false,
      };
    case paymentsActionsType.loadPaymentCardsError:
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    case paymentsActionsType.addNewCard:
      return {
        cards: {
          ...state.cards,
          cards: [...state.cards.cards, action.card],
        },
        isError: true,
        isFetching: false,
      };
    case paymentsActionsType.updateSelectedCard:
      return {
        cards: {
          ...state.cards,
          selected_card: action.cardId,
        },
        isError: true,
        isFetching: false,
      };
    case paymentsActionsType.deleteCardSuccess:
      const prevState = state.cards;
      return {
        cards: {
          ...prevState,
          cards: prevState.cards.filter(card => card.id !== action.cardId),
          selected_card: prevState.cards.length - 1 === 0 ? '' : prevState.selected_card
        },
        isError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
