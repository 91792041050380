import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { STORAGE_ITEMS } from "@consts/storage";
import { ROLES } from "@consts/roles";
import { EWidgetTypeEntrance } from "@consts/global";

@Injectable({
  providedIn: 'root'
})
export class WidgetAuthorizedGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeKeys = { ...route.params, ...route.queryParams };
    const isHomeownerAccess = !!localStorage.getItem('homeowner_access');

    if (routeKeys.hasOwnProperty(EWidgetTypeEntrance.Token) && !!routeKeys?.deeplink) {
      return this.router.createUrlTree([`deeplink/${ routeKeys?.deeplink }`]);
    }
    if (localStorage.getItem(STORAGE_ITEMS.TOKEN)) {
      return this.router.createUrlTree(['home-page/main-page']);
    }
    if (routeKeys.hasOwnProperty(EWidgetTypeEntrance.Email) && !!routeKeys?.email) {
      const email = (routeKeys?.email && routeKeys?.email !== 'null') ? routeKeys?.email : '';
      localStorage.setItem(STORAGE_ITEMS.ROLE, ROLES.TENANT);
      localStorage.setItem(STORAGE_ITEMS.TENANT_EMAIL, email);
      return this.router.createUrlTree([isHomeownerAccess ? '' : `auth/sign-in`]);
    }
    return this.router.createUrlTree(['']);
  }
}
