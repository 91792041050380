import { CallHistoryActions, callHistoryActionsType } from './call-history.actions';
import { IChatHistory } from '@interfaces/chat-history.interface';

export const callHistoryFeatureKey = 'callHistory';

export interface ICallHistoryState {
  data: IChatHistory | null,
  isFetching: boolean,
  isError: boolean
}

export const initialState: ICallHistoryState = {
  data: null,
  isFetching: false,
  isError: false
};

export const callHistoryReducer = (state = initialState, action: CallHistoryActions) => {
  switch (action.type) {
    case callHistoryActionsType.loadCallHistory:
      return {
        ...state,
        isError: false,
        isFetching: true,
      };
    case callHistoryActionsType.loadCallHistorySuccess:
      const isLoadMore = action.isLoadMore;
      const chatHistory = action.chatHistory;
      return {
        data: isLoadMore ? {
          ...state.data,
          total: chatHistory.total,
          calls: [...state.data.calls, ...chatHistory.calls]
        } : chatHistory,
        isError: false,
        isFetching: false,
      };
    case callHistoryActionsType.loadCallHistoryError:
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
