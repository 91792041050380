import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

const urlPrefixes: string[] = ['assets', 'http', 'https'];

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.checkPrefix(request.url) && this.ignoreApi(request.url)) {
      request = request.clone({
        url: `${ environment.api_url }${ request.url }`
      });
    }

    return next.handle(request)
  }

  private checkPrefix = (url: string): boolean => urlPrefixes.every((prefix) => !url.includes(prefix));

  private ignoreApi = (url: string): boolean => !url.includes('http://ipinfo.io');
}
