import { SessionActions, sessionActionsType } from './session.actions';
import { ICaptionsState } from '@interfaces/call.interfaces';

export const sessionFeatureKey = 'session';

export interface ISessionStateData {
  sessionId: string,
  callId: string,
  isMirror: boolean,
  isChatOn: boolean,
  isNotificationMessage: boolean,
  isRecordCall: boolean,
}

export interface IWorkerStateData {
  call_duration: number,
  call_id: number,
  call_rate: number,
  sender_first_name: string,
  sender_id: number,
  sender_last_name: string,
  sender_photo: string,
  session_id: string,
}

export interface IDevicesStateData {
  audioDeviceId: string,
  audioDeviceLabel: string,
  videoDeviceId: string,
  videoDeviceLabel: string,
  audio: boolean,
  video: boolean,
}

export interface ISessionState {
  session: ISessionStateData | null,
  worker: IWorkerStateData | null,
  devices: IDevicesStateData | null,
  captions: ICaptionsState,
}

export const initialState: ISessionState = {
  session: null,
  worker: null,
  devices: null,
  captions: {
    status: {
      is_worker_captions_enabled: false,
      is_user_captions_enabled: false,
      worker_language: 'en',
      user_language: 'en',
    },
    loading: false,
    error: null,
  }
};

export const sessionReducer = (state = initialState, action: SessionActions) => {
  switch (action.type) {
    case sessionActionsType.setSessionParams:
      return {
        ...state,
        session: action.session
      };
    case sessionActionsType.setDevicesState:
      return {
        ...state,
        devices: action.devices
      };
    case sessionActionsType.setWorkerState:
      return {
        ...state,
        worker: action.worker
      };
    case sessionActionsType.clearWorkerState:
      return {
        ...state,
        worker: null
      };
    case sessionActionsType.toggleMirrorState:
      return {
        ...state,
        session: {
          ...state.session,
          isMirror: typeof action.mirror === 'boolean' ? action.mirror : !state.session?.isMirror
        }
      };
    case sessionActionsType.toggleChatState:
      return {
        ...state,
        session: {
          ...state.session,
          isChatOn: !state.session.isChatOn,
          isNotificationMessage: false
        }
      };
    case sessionActionsType.setNotificationMessageState:
      return {
        ...state,
        session: {
          ...state.session,
          isNotificationMessage: action.state
        }
      };
    case sessionActionsType.changeDevicesState:
      return {
        ...state,
        devices: {
          ...state.devices,
          ...action.devices
        }
      };
    case sessionActionsType.setCaptionsStatusFromSocket:
      return {
        ...state,
        captions: {
          status: action.data,
          loading: false,
          error: null
        }
      };
    case sessionActionsType.toggleCaptionsStatus:
      return {
        ...state,
        captions: {
          ...state.captions,
          loading: true,
          error: null
        }
      };
    case sessionActionsType.toggleCaptionsSuccessStatus:
      return {
        ...state,
        captions: {
          status: {
            ...state.captions.status,
            is_user_captions_enabled: action.captions
          },
          loading: false,
          error: null
        }
      };
    case sessionActionsType.toggleCaptionsErrorStatus:
      return {
        ...state,
        captions: {
          ...state.captions,
          loading: false,
          error: action.error
        }
      };
    case sessionActionsType.clearCaptionsStatusStateAction:
      return {
        ...state,
        captions: {
          status: {
            is_worker_captions_enabled: false,
            is_user_captions_enabled: false,
            worker_language: 'en',
            user_language: 'en',
          },
          loading: false,
          error: null,
        }
      };
    default:
      return state;
  }
};
