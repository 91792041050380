import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { CommonPagesRoutingModule } from "./common-pages-routing.module";

import { CommonService } from '@services/common.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    CommonPagesRoutingModule
  ],
  providers: [
    CommonService
  ]
})
export class CommonPagesModule { }
