import { ServicesActions, servicesActionsType } from './services.actions';
import { ICategoriesResp } from '@interfaces/categories.interface';

export const servicesFeatureKey = 'services';

export interface IServicesState {
  data: ICategoriesResp | null,
  isFetching: boolean,
  isError: boolean
}

export const initialState: IServicesState = {
  data: null,
  isFetching: false,
  isError: false
};

export const servicesReducer = (state = initialState, action: ServicesActions) => {
  switch (action.type) {
    case servicesActionsType.loadServicesState:
      return {
        ...state,
        isError: false,
        isFetching: true,
      };
    case servicesActionsType.loadServicesStateSuccess:
      return {
        data: action.services,
        isError: false,
        isFetching: false,
      };
    case servicesActionsType.loadServicesStateError:
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
