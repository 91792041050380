import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { STORAGE_ITEMS } from "@consts/storage";

@Injectable({ providedIn: 'root' })
export class AuthorizedGuard implements CanActivate {

  constructor(
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | UrlTree | boolean {
    if (localStorage.getItem(STORAGE_ITEMS.TOKEN)) {
      return this.router.createUrlTree(['home-page/main-page']);
    }
    return true;
  }
}
