import { Action } from '@ngrx/store';

import { IChatHistory } from '@interfaces/chat-history.interface';

export enum callHistoryActionsType {
  loadCallHistory = '[CallHistory] Load call history',
  loadCallHistorySuccess = '[CallHistory] Load call history success',
  loadCallHistoryError = '[CallHistory] Load call history error',
}

export class LoadCallHistoryAction implements Action {
  readonly type = callHistoryActionsType.loadCallHistory;

  constructor(public page: number, public isLoadMore: boolean = true, public limit: number = 5) {
  }
}

export class LoadCallHistorySuccessAction implements Action {
  readonly type = callHistoryActionsType.loadCallHistorySuccess;

  constructor(public chatHistory: IChatHistory, public isLoadMore: boolean = true) {
  }
}

export class LoadCallHistoryErrorAction implements Action {
  readonly type = callHistoryActionsType.loadCallHistoryError;

  constructor(public error: any) {
  }
}

export type CallHistoryActions = LoadCallHistoryAction | LoadCallHistorySuccessAction | LoadCallHistoryErrorAction;
