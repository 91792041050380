import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  HostListener,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, interval, Observable, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, map, startWith, takeUntil, tap } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { SwUpdate } from '@angular/service-worker';

// Store
import { getIsFetchingWidget, getIsWidgetStatus } from './reducers/widget/widget.selectors';
import { getLoader } from './reducers/loader/loader.selectors';
import { getPlayAudio } from './reducers/play-audio/play-audio.selectors';
import { getSocketToken } from './reducers/profile/profile.selectors';

// Services
import { ResizeDetectionService } from "@services/resize-detection.service";
import { UserCallsService } from "@services/user-calls.service";
import { PushNotificationService } from "@services/push-notification.service";
import { UserService } from "@services/user.service";
import { WebSocketsService } from '@services/web-sockets/web-sockets.service';
import { SystemNotificationsService } from '@services/system-notifications.service';
import { WindowService } from '@services/window.service';

// Models
import { IChatMsg } from '@interfaces/temporary-user.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('audioElement', { static: false }) audioElement: ElementRef<HTMLAudioElement>;

  private timerId: any;

  public combined$: Observable<{ isWidget: boolean, isFetchingWidget: boolean, loaderStatus: boolean }> = this.getCombineObservables();
  private destroy$: Subject<void> = new Subject<void>();
  private socketSubscription$: Subscription;
  private playError: boolean = false;

  constructor(
    private serviceWorkerService: SwUpdate,
    private resizeDetectionService: ResizeDetectionService,
    private userService: UserService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userCallService: UserCallsService,
    private pushNotificationService: PushNotificationService,
    private systemNotificationsService: SystemNotificationsService,
    private webSocketsService: WebSocketsService,
    private windowService: WindowService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.initServiceWorker();
    this.resizeDetectionService.initService();
  }

  ngOnInit(): void {
    this.detectUpdates();
    this.checkPlayAudioState();
    if (!localStorage.getItem('link_type')) {
      localStorage.setItem('link_type', 'WEB');
    }
    // TODO
    // if (localStorage.getItem('link_type') === 'FRAME') {
    //   this.frameState = true;
    // }
    this.userCallService.setUserType();
    this.checkUserAuthStatus();
    this.checkSocketTokenChanges();
  }

  ngAfterViewInit(): void {
    if (this.serviceWorkerService.isEnabled) {
      this.serviceWorkerService.checkForUpdate();
    }

    if (Object.keys(this.userService.getUser()).length && !window.location.href.includes('deeplink')) {
      this.pushNotificationService.requestPermission();
      // this.pushNotificationService.receiveMessage();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.closeWebSocketConnection();
    this.socketSubscription$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCombineObservables(): Observable<{ isWidget: boolean, isFetchingWidget: boolean, loaderStatus: boolean }> {
    return combineLatest([
      this.store.select(getIsWidgetStatus).pipe(startWith(null)),
      this.store.select(getIsFetchingWidget).pipe(startWith(null)),
      this.store.select(getLoader).pipe(startWith(null))
    ])
      .pipe(
        map(([isWidget, isFetchingWidget, loaderStatus]) => {
          return { isWidget, isFetchingWidget, loaderStatus };
        })
      );
  }

  playAudio(audioElement: HTMLAudioElement): void {
    audioElement?.load();
    audioElement?.play()
      .catch(error => {
        this.playError = true;
      });
  }

  setAudioInterval(audioElement: HTMLAudioElement): void {
    if (!audioElement) return;
    this.playAudio(audioElement);
    this.timerId = setInterval(() => {
      if (this.playError) {
        clearTimeout(this.timerId);
        return;
      }
      this.playAudio(audioElement);
    }, 3200);
  }

  checkPlayAudioState(): void {
    this.store.select(getPlayAudio)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (playAudio) => {
          const audioElement: HTMLAudioElement = this.audioElement?.nativeElement;
          if (!playAudio && audioElement) {
            clearInterval(this.timerId);
            return;
          }
          this.setAudioInterval(audioElement);
        }
      });
  }

  initServiceWorker(): void {
    if (!this.serviceWorkerService.isEnabled) return;
    interval(900000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.serviceWorkerService.checkForUpdate();
      });
  }

  detectUpdates(): void {
    this.serviceWorkerService.available
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (event) => {
          const isCallProcess = this.router.url.includes('call');
          const isSameHash = event.current.hash === event.available.hash;
          if (isSameHash || isCallProcess) return;
          this.systemNotificationsService.openUpdateVersionSnackBar();
          this.serviceWorkerService.activateUpdate();
        }
      });
    this.serviceWorkerService.activated
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (event) => {
          const timerId = setTimeout(() => {
            window.location.reload();
            clearTimeout(timerId);
          }, 5000);
        }
      });
  }

  checkSocketTokenChanges(): void {
    this.store.select(getSocketToken)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (socketToken) => {
          if (socketToken === null) {
            this.closeWebSocketConnection();
            return;
          }
          const instanceToken = this.webSocketsService.getSocketToken();
          if (instanceToken === socketToken) return;
          this.closeWebSocketConnection();
          this.openWebSocketConnection();
        }
      })
  }

  checkUserAuthStatus(): void {
    this.userService.isAuthUser$
      .pipe(
        tap((isAuthUser) => console.log('isAuthUser', isAuthUser)),
        takeUntil(this.destroy$),
        distinctUntilChanged()
      )
      .subscribe({
        next: (authStatus) => {
          if (!authStatus) {
            this.closeWebSocketConnection();
            return;
          }
          this.openWebSocketConnection();
        }
      })
  }

  openWebSocketConnection(): void {
    this.webSocketsService.createConnection();
    if (this.socketSubscription$) {
      this.socketSubscription$.unsubscribe();
    }
    this.socketSubscription$ = this.webSocketsService.messagesSubject$
      .subscribe({
          next: (data) => {
            if (!data) return;
            switch (data.type) {
              case 'chat-message':
                this.sendMessageNotification(data.data);
                break;
            }
          }
        }
      );
  }

  closeWebSocketConnection(): void {
    this.webSocketsService.disconnectSocket();
  }

  sendMessageNotification(data: IChatMsg): void {
    const callId = +this.activatedRoute.snapshot.queryParams?.call_id;
    if (this.router.url.includes('call-process') || callId === data.call_id) {
      return;
    }
    this.windowService.changeTitle();
    this.systemNotificationsService.openMessageNotificationSnackBar(
      'You have a new message from an engineer: ' + data.sender_first_name + ' ' + data.sender_last_name,
      data.call_id
    );
  }
}
