import { createSelector } from '@ngrx/store';

import { IState } from '../index';
import { IProfileState, profileFeatureKey } from './profile.reducer';
import { IAuthResp } from '@interfaces/user.interface';

// Selector State
export const getProfileState = (state: IState): IProfileState => state[profileFeatureKey];

// Callbacks selectors
export const selectSocketToken = (state: IProfileState): string | null => state.socketToken;
export const selectProfileData = (state: IProfileState): IAuthResp | null => state.data;
export const selectProfileFetchingStatus = (state: IProfileState): boolean => state.isFetching;
export const selectProfileErrorStatus = (state: IProfileState): boolean => state.isError;

// Selector Data
export const getSocketToken = createSelector(getProfileState, selectSocketToken);
export const getProfileData = createSelector(getProfileState, selectProfileData);
export const getIsFetchingProfile = createSelector(getProfileState, selectProfileFetchingStatus);
export const getIsErrorProfile = createSelector(getProfileState, selectProfileErrorStatus);
