// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: true,
  api_url: 'https://client.helpmefix.io/api',
  stripe: {
    publishKey: 'pk_live_5hJ81nNWVJeMHRmxujie9Byo00w6NY5YFD'
  },
  api_key: '46478352',
  // ws_url: 'wss://client.helpmefix.io/ws',
  ws_url: 'wss://ws.client.helpmefix.io/',
  ws_channel_name: 'help_me_fix_prod_database_private-App.User.',
  countryAPI: 'https://ipinfo.io/json?token=86eab8f60d621b',
  firebase: {
    apiKey: "AIzaSyBZ6dc5UC8bosKTWs3ej1pmuyrOkPrHyis",
    authDomain: "helpmefix-dca49.firebaseapp.com",
    databaseURL: "https://helpmefix-dca49.firebaseio.com",
    projectId: "helpmefix-dca49",
    storageBucket: "helpmefix-dca49.appspot.com",
    messagingSenderId: "642697303611",
    appId: "1:642697303611:web:cb40a1f9a0cd80df71ae1e",
    measurementId: "G-0VGV1X1NR3"
  },
  deepgramAPIkey: "9650c58e0d80043b028c2a39eddcefe67834f44c",
  deepgramWssUrl: "wss://api.deepgram.com/v1/listen",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
