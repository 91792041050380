<div class="snack-bar">
  <div class="snack-bar-close">
    <mat-icon *ngIf="ENotificationTypes.ATTENTION !== type" svgIcon="close" (click)="closeShackBar()"></mat-icon>
    <mat-icon class="rolling-icon" *ngIf="ENotificationTypes.ATTENTION === type" svgIcon="rolling"></mat-icon>
  </div>
  <div class="snack-bar-message" [class.opened-text]="!!callId" (click)="openChatWithEngineer()">
    {{ message }} <span *ngIf="callId">(click to open)</span>
  </div>
</div>

<audio controls preload="metadata" class="audio-element" #audioElement src="/assets/notification.mp3"></audio>
