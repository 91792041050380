/**
 * Configuration options for WebSocket connection.
 *
 * @typedef {Object} WebSocketConfig
 * @property {boolean} reconnection - Whether to enable automatic reconnection in case of connection failure.
 * @property {number} reconnectionDelay - The delay (in milliseconds) before attempting to reconnect after connection failure.
 * @property {number} reconnectionDelayMax - The maximum delay (in milliseconds) before attempting to reconnect after connection failure.
 */

/**
 * The WebSocket configuration.
 *
 * @type {WebSocketConfig}
 */
export const CWebSocketConfig = {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
}

/**
 * The interval (in milliseconds) for sending ping messages to the server.
 *
 * @type {number}
 */
export const CWebSocketPingInterval = 20000;

/**
 * The interval (in milliseconds) for waiting for a pong message from the server.
 *
 * @type {number}
 */
export const CWebSocketPongWaitingInterval = 20000;
