export enum EPaymentActions {
  CardDeleteSuccess = 'Payment card successfully removed',
  CardDeleteFailed = 'Your payment card could not be deleted, please try again later',
  CardSaveFailed = 'Your payment card could not be saved, please try again later'
}

export enum EUserProfileActions {
  DeleteUserProfile = 'User profile successfully deleted!',
  DeleteUserProfilePicture = 'User profile picture successfully deleted!',
  UpdateUserInfoSuccess = 'User info successfully updated!',
  GetUserInfo = 'Get user info is failed! Please reload the page',
  UserInfoSaveFailed = 'Something went wrong, failed to update profile data. Check your internet connection or try again later.',
  UserPasswordChangeFailed = 'Something went wrong, failed to update profile data. Check your internet connection or try again later.',
  UserPhotoChangeSuccess = 'Photo has been successfully updated',
  UserPhotoChangeFailed = 'Photo has not been updated. Check your internet connection or try again later.'
}
