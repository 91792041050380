export const propertiesTheme = {
  '--primary_light': '#B0CB1F',
  '--primary_brand': '#B0CB1F',
  // '--primary_dark': '#0C0D4B',
  // '--tint_primary': '#C4C4C4',
  // '--tint_secondary': '#E5E5E5',
  '--accent_success': '#B0CB1F',
  '--accent_error': '#CE2C3F',
  '--logo': 'url(/assets/images/svg/main_logo.svg)',
  '--link-color': '#EF8423',
}
