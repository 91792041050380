import { SingleServiceActions, singleServiceActionsType } from './single-service.actions';

export const singleServiceFeatureKey = 'single-service';

export interface ISingleServiceState {
  data: null | {
    icon: string,
    id: number,
    name: string
  }
  state: boolean,
}

export const initialState: ISingleServiceState = {
  state: false,
  data: null
};

export const singleServiceReducer = (state = initialState, action: SingleServiceActions) => {
  switch (action.type) {
    case singleServiceActionsType.setSingleServiceState:
      return {
        data: action.data,
        state: true
      };
    case singleServiceActionsType.clearSingleServiceState:
      return {
        data: null,
        state: false
      };
    default:
      return state;
  }
};
