import { createSelector } from '@ngrx/store';

import { IState } from '../index';
import { ILoaderState, loaderFeatureKey } from './loader.reducer';

// Selector State
export const getLoaderState = (state: IState): ILoaderState => state[loaderFeatureKey];

// Callbacks selectors
export const selectLoader = (state: ILoaderState): boolean => state.state;

// Selector Data
export const getLoader = createSelector(getLoaderState, selectLoader);
