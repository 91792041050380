/**
 * An enumeration that defines WebSocket events.
 *
 * @readonly
 * @enum {string}
 */
export enum EWebSocketEvent {
  /**
   * The "connect" event.
   */
  Connect = 'connect',

  /**
   * The "ping" event.
   */
  Ping = 'ping',

  /**
   * The "pong" event.
   */
  Pong = 'pong',

  /**
   * The "connectToRoom" event.
   */
  ConnectToRoom = 'connectToRoom',

  /**
   * The "transcriptions-to-translate" event.
   */
  TranscriptionsToTranslate = 'transcriptions-to-translate',

  /**
   * The "transcriptions-with-translate" event.
   */
  TranscriptionsWithTranslate = 'transcriptions-with-translate',

  /**
   * The "transcriptions-with-translate" event.
   */
  TranscriptionsStatusChanges = 'transcription-status-changed',

  /**
   * The "whiteboard" event.
   */
  Whiteboard = 'whiteboard',
}
