import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// Store
import {
  ToggleCaptionsStatusErrorAction,
  ToggleCaptionsStatusSuccessAction,
  sessionActionsType
} from './session.actions';

// Services
import { UserCallsService } from '@services/user-calls.service';

@Injectable()
export class SessionEffects {

  constructor(
    private actions$: Actions,
    private userCallService: UserCallsService,
  ) {
  }

  toggleCaptionsStatus$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActionsType.toggleCaptionsStatus),
    switchMap(({ data: { captions, call_id } }) => {
      return this.userCallService.toggleCaptionsStatus(captions, call_id)
        .pipe(
          map(() => {
            return new ToggleCaptionsStatusSuccessAction(captions);
          }),
          catchError((error) => {
            return of(new ToggleCaptionsStatusErrorAction(error));
          })
        );
    })
  ));
}
