import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { NotificationTypes } from '@consts/global';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationsService {
  private snackBarCustomData: MatSnackBarConfig = {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    duration: 5000
  }

  // public sendNotificationEvent = new Subject<any>();

  constructor(private snackbarService: MatSnackBar) {
  }

  openMessageNotificationSnackBar(message: string, callId: number): void {
    this.snackbarService.openFromComponent(SnackBarComponent, {
      ...this.snackBarCustomData,
      duration: 10000,
      panelClass: 'success-snackbar',
      data: {
        type: NotificationTypes.SUCCESS,
        message, callId
      }
    });
  }

  openUpdateVersionSnackBar(): void {
    this.snackbarService.openFromComponent(SnackBarComponent, {
      ...this.snackBarCustomData,
      duration: 1000000,
      panelClass: 'update-version-snackbar',
      data: {
        type: NotificationTypes.ATTENTION,
        message: 'A new version of the site has been found. It is being uploaded. The page will be reloaded when it is finished loading.'
      }
    });
  }

  openSuccessSnackBar(message: string): void {
    this.snackbarService.openFromComponent(SnackBarComponent, {
      ...this.snackBarCustomData,
      panelClass: 'success-snackbar',
      data: {
        type: NotificationTypes.SUCCESS,
        message
      }
    });
  }

  openErrorSnackBar(message: string): void {
    this.snackbarService.openFromComponent(SnackBarComponent, {
      ...this.snackBarCustomData,
      panelClass: 'error-snackbar',
      data: {
        type: NotificationTypes.ERROR,
        message
      }
    });
  }

  // openWarningSnackBar(message: string): void {
  //   this.snackbarService.openFromComponent(SnackBarComponent, {
  //     ...this.snackBarCustomData,
  //     panelClass: 'warning-snackbar',
  //     data: {
  //       type: NotificationTypes.WARNING,
  //       message
  //     }
  //   });
  // }
}
