import { Action } from '@ngrx/store';

export enum singleServiceActionsType {
  setSingleServiceState = '[SingleService] Set single service state data',
  clearSingleServiceState = '[SingleService] Clear single service state data',
}

export class SetSingleServiceStateAction implements Action {
  readonly type = singleServiceActionsType.setSingleServiceState;

  constructor(public data: { icon: string, id: number, name: string }) {}
}

export class ClearSingleServiceStateAction implements Action {
  readonly type = singleServiceActionsType.clearSingleServiceState;
}

export type SingleServiceActions = SetSingleServiceStateAction | ClearSingleServiceStateAction;
