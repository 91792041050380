import { LanguagesActions, languagesActionsType } from './languages.actions';
import { ILanguage } from '@interfaces/languages.interfaces';

export const languagesFeatureKey = 'languages';

export interface ILanguagesState {
  languages: ILanguage[],
  loading: boolean,
  error: string | null
}

export const initialState: ILanguagesState = {
  languages: [],
  loading: false,
  error: null,
};

export const languagesReducer = (state = initialState, action: LanguagesActions) => {
  switch (action.type) {
    case languagesActionsType.loadLanguagesList:
      return { ...state, loading: true, error: null };
    case languagesActionsType.loadLanguagesListSuccess:
      return { languages: action.languages, loading: false, error: null };
    case languagesActionsType.loadLanguagesListError:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
