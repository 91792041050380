import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { UserService } from './user.service';
import { STORAGE_ITEMS } from "@consts/storage";
import { SystemNotificationsService } from "@services/system-notifications.service";
import { RoleHelper } from "@helpers/role-helper";

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private userService: UserService,
    private systemNotification: SystemNotificationsService
  ) {
    this.subscribeMessages();
  }

  subscribeMessages(): void {
    this.angularFireMessaging.messages
      .subscribe((_messaging: any) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        }
      );
  }

  updateUDID(token: string): void {
    const data = {
      device_UDID: token
    }
    this.userService.updateUDID(data).subscribe(resp => {
    })
  }

  requestPermission(): void {
    this.angularFireMessaging.requestToken
      .subscribe((token) => {
          if (!token) return;
          localStorage.setItem(STORAGE_ITEMS.UDID, token);
          if (!RoleHelper.isTemporary()) {
            this.updateUDID(token);
          }
        }
      );
  }

  // receiveMessage(): void {
  //   this.angularFireMessaging.messages
  //     .subscribe((payload: any) => {
  //       this.systemNotification.sendNotificationEvent.next(payload.notification)
  //     });
  // }
}
