import { PlayAudioActions, playAudioActionsType } from './play-audio.actions';

export const playAudioFeatureKey = 'playAudio';

export interface IPlayAudioState {
  state: boolean,
}

export const initialState: IPlayAudioState = {
  state: false
};

export const playAudioReducer = (state = initialState, action: PlayAudioActions) => {
  switch (action.type) {
    case playAudioActionsType.setPlayAudioState:
      return {
        state: action.state
      };
    default:
      return state;
  }
};
