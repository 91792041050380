import { Action } from '@ngrx/store';
import { IDevicesStateData, ISessionStateData, IWorkerStateData } from './session.reducer';
import { ICaptions } from '@interfaces/call.interfaces';

export enum sessionActionsType {
  setSessionParams = '[Session] Set session params',
  setCaptionsStatusFromSocket = '[Session] Set captions status state from socket',
  toggleCaptionsStatus = '[Session] Toggle captions state',
  toggleCaptionsSuccessStatus = '[Session] Toggle captions state success',
  toggleCaptionsErrorStatus = '[Session] Toggle captions state error',
  clearCaptionsStatusStateAction = '[Session] Clear captions state',
  toggleMirrorState = '[Session] Toggle mirror state',
  toggleChatState = '[Session] Toggle chat state',
  setDevicesState = '[Session] Set devices state',
  setNotificationMessageState = '[Session] Set notification message state',
  changeDevicesState = '[Session] Change devices state',
  setWorkerState = '[Session] Set worker state',
  clearWorkerState = '[Session] Clear worker state',
}

export class SetSessionParamsAction implements Action {
  readonly type = sessionActionsType.setSessionParams;

  constructor(public session: ISessionStateData) {
  }
}

export class ToggleMirrorStateAction implements Action {
  readonly type = sessionActionsType.toggleMirrorState;

  constructor(public mirror?: boolean) {
  }
}

export class SetCaptionsStatusFromSocketAction implements Action {
  readonly type = sessionActionsType.setCaptionsStatusFromSocket;

  constructor(public data: ICaptions) {
  }
}

export class ToggleCaptionsStatusAction implements Action {
  readonly type = sessionActionsType.toggleCaptionsStatus;

  constructor(public data: {
    captions: boolean, call_id: number | string
  }) {
  }
}

export class ToggleCaptionsStatusSuccessAction implements Action {
  readonly type = sessionActionsType.toggleCaptionsSuccessStatus;

  constructor(public captions: boolean) {
  }
}

export class ToggleCaptionsStatusErrorAction implements Action {
  readonly type = sessionActionsType.toggleCaptionsErrorStatus;

  constructor(public error?: any) {
  }
}

export class ClearCaptionsStatusStateAction implements Action {
  readonly type = sessionActionsType.clearCaptionsStatusStateAction;
}

export class ToggleChatStateAction implements Action {
  readonly type = sessionActionsType.toggleChatState;
}

export class SetDevicesStateAction implements Action {
  readonly type = sessionActionsType.setDevicesState;

  constructor(public devices: IDevicesStateData) {
  }
}

export class SetNotificationMessageAction implements Action {
  readonly type = sessionActionsType.setNotificationMessageState;

  constructor(public state: boolean) {
  }
}

export class ChangeDevicesStateAction implements Action {
  readonly type = sessionActionsType.changeDevicesState;

  constructor(public devices: {
    audio?: boolean,
    video?: boolean,
  }) {
  }
}

export class SetWorkerStateAction implements Action {
  readonly type = sessionActionsType.setWorkerState;

  constructor(public worker: IWorkerStateData) {
  }
}

export class ClearWorkerStateAction implements Action {
  readonly type = sessionActionsType.clearWorkerState;
}

export type SessionActions = SetSessionParamsAction |
  SetDevicesStateAction |
  SetWorkerStateAction |
  ClearWorkerStateAction |
  ChangeDevicesStateAction |
  ToggleMirrorStateAction |
  SetCaptionsStatusFromSocketAction |
  ToggleChatStateAction |
  ToggleCaptionsStatusAction |
  ToggleCaptionsStatusSuccessAction |
  ToggleCaptionsStatusErrorAction |
  ClearCaptionsStatusStateAction |
  SetNotificationMessageAction;
