import { CommonActions, commonActionsType } from './common.actions';

import { CommonTermAndPolicyModel } from '@interfaces/user.interface';

export const commonFeatureKey = 'common';

export interface ICommonState {
  privacy: CommonTermAndPolicyModel | null,
  terms: CommonTermAndPolicyModel | null,
  fetching: {
    isPrivacy: boolean,
    isTerms: boolean
  },
  error: {
    isPrivacy: boolean,
    isTerms: boolean
  }
}

export const initialState: ICommonState = {
  privacy: null,
  terms: null,
  fetching: {
    isPrivacy: false,
    isTerms: false
  },
  error: {
    isPrivacy: false,
    isTerms: false
  }
};

export const commonReducer = (state = initialState, action: CommonActions) => {
  switch (action.type) {
    case commonActionsType.loadPrivacyState:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPrivacy: true
        },
        error: {
          ...state.error,
          isPrivacy: false
        },
      };
    case commonActionsType.loadPrivacyStateSuccess:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPrivacy: false
        },
        error: {
          ...state.error,
          isPrivacy: false
        },
        privacy: action.privacy,
      };
    case commonActionsType.loadPrivacyStateError:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPrivacy: false
        },
        error: {
          ...state.error,
          isPrivacy: true
        }
      };
    case commonActionsType.loadTermsState:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isTerms: true
        },
        error: {
          ...state.error,
          isTerms: false
        },
      };
    case commonActionsType.loadTermsStateSuccess:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isTerms: false
        },
        error: {
          ...state.error,
          isTerms: false
        },
        terms: action.terms
      };
    case commonActionsType.loadTermsStateError:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isTerms: false
        },
        error: {
          ...state.error,
          isTerms: true
        }
      };
    default:
      return state;
  }
};
