import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthorizedGuard } from "@guards/authorized.guard";
import { WidgetAuthorizedGuard } from "@guards/widget-authorized.guard";

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./common-pages/common-pages.module').then(
        (m) => m.CommonPagesModule
      )
  },
  {
    path: 'auth',
    canActivate: [AuthorizedGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        (m) => m.AuthModule
      )
  }, {
    path: 'widgets/:id',
    canActivate: [WidgetAuthorizedGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        (m) => m.AuthModule
      )
  },
  {
    path: 'home-page',
    loadChildren: () =>
      import('./modules/home-page/home-page.module').then(
        (m) => m.HomePageModule
      )
  },
  {
    path: 'user-profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      )
  },
  {
    path: 'call',
    loadChildren: () =>
      import('./modules/call/call.module').then(
        (m) => m.CallModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
