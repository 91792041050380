import { Component, OnInit } from '@angular/core';

// Services
import { WidgetsService } from "@services/widgets.service";

// Models
import { STORAGE_ITEMS } from "@consts/storage";

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  public widgetStatus: boolean;
  public frameStatus: boolean;

  constructor(public widgetsService: WidgetsService) {
  }

  ngOnInit(): void {
    this.setStatusApp();
  }

  setStatusApp(): void {
    const linkType = localStorage.getItem(STORAGE_ITEMS.LINK_TYPE);
    this.frameStatus = linkType ? linkType === 'FRAME' : false;
    this.widgetStatus = this.widgetsService.getWidgetStatus();
  }
}
