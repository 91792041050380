import { Action } from '@ngrx/store';

import { CommonTermAndPolicyModel } from '@interfaces/user.interface';

export enum commonActionsType {
  initCommonState = '[Common] Init common state',
  loadPrivacyState = '[Common] Set privacy state',
  loadPrivacyStateSuccess = '[Common] Set privacy state success',
  loadPrivacyStateError = '[Common] Set privacy state error',
  loadTermsState = '[Common] Set terms state',
  loadTermsStateSuccess = '[Common] Set terms state success',
  loadTermsStateError = '[Common] Set terms state error',
}

export class InitCommonStateAction implements Action {
  readonly type = commonActionsType.initCommonState;
}

export class LoadPrivacyStateAction implements Action {
  readonly type = commonActionsType.loadPrivacyState;
}

export class LoadPrivacyStateSuccessAction implements Action {
  readonly type = commonActionsType.loadPrivacyStateSuccess;

  constructor(public privacy: CommonTermAndPolicyModel) {
  }
}

export class LoadPrivacyStateErrorAction implements Action {
  readonly type = commonActionsType.loadPrivacyStateError;

  constructor(public error: any) {
  }
}

export class LoadTermsStateAction implements Action {
  readonly type = commonActionsType.loadTermsState;
}

export class LoadTermsStateSuccessAction implements Action {
  readonly type = commonActionsType.loadTermsStateSuccess;

  constructor(public terms: CommonTermAndPolicyModel) {
  }
}

export class LoadTermsStateErrorAction implements Action {
  readonly type = commonActionsType.loadTermsStateError;

  constructor(public error: any) {
  }
}

export type CommonActions = InitCommonStateAction |
  LoadPrivacyStateAction |
  LoadPrivacyStateSuccessAction |
  LoadPrivacyStateErrorAction |
  LoadTermsStateAction |
  LoadTermsStateSuccessAction |
  LoadTermsStateErrorAction;
