import { Injectable } from '@angular/core';

import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResizeDetectionService {

  public screenSize: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public screenSizePX$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private screenMode: number = null;

  public initService(): void {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen.bind(this));
  }

  private checkScreen(): void {
    this.screenMode = document.body.clientWidth;
    this.screenSizePX$.next( document.body.clientWidth);
    if (this.screenMode > 641 && this.screenMode <= 1007) {
      return this.screenSize.next('tablet');
    }
    if (this.screenMode > 1007) {
      return this.screenSize.next('desktop');
    }
    return this.screenSize.next('');
  }
}
