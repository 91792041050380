import { createSelector } from '@ngrx/store';

import { IWidgetState, widgetFeatureKey } from './widget.reducer';
import { IWidgetsData } from '@interfaces/widgets.interface';
import { IState } from '../index';

// Selector State
export const getWidgetState = (state: IState): IWidgetState => state[widgetFeatureKey];

// Callbacks selectors
export const selectWidgetData = (state: IWidgetState): IWidgetsData | null => state.data;
export const selectWidgetStatus = (state: IWidgetState): boolean => state.isWidget;
export const selectWidgetFetchingStatus = (state: IWidgetState): boolean => state.isFetching;
export const selectWidgetErrorStatus = (state: IWidgetState): boolean => state.isError;

// Selector Data
export const getWidgetData = createSelector(getWidgetState, selectWidgetData);
export const getIsWidgetStatus = createSelector(getWidgetState, selectWidgetStatus);
export const getIsFetchingWidget = createSelector(getWidgetState, selectWidgetFetchingStatus);
export const getIsErrorWidget = createSelector(getWidgetState, selectWidgetErrorStatus);
