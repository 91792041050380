import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';

import { environment } from '@environments/environment';
import { IWidgetState, widgetFeatureKey, widgetReducer } from './widget/widget.reducer';
import { IProfileState, profileFeatureKey, profileReducer } from './profile/profile.reducer';
import { ILoaderState, loaderFeatureKey, loaderReducer } from './loader/loader.reducer';
import { IServicesState, servicesFeatureKey, servicesReducer } from './services/services.reducer';
import { ICallHistoryState, callHistoryFeatureKey, callHistoryReducer } from './call-history/call-history.reducer';
import { ISessionState, sessionFeatureKey, sessionReducer } from './session/session.reducer';
import { IPlayAudioState, playAudioFeatureKey, playAudioReducer } from './play-audio/play-audio.reducer';
import { ICommonState, commonFeatureKey, commonReducer } from './common/common.reducer';
import { IPaymentsState, paymentsFeatureKey, paymentsReducer } from './payments/payments.reducer';
import { ILanguagesState, languagesFeatureKey, languagesReducer } from './languages/languages.reducer';
import {
  ISingleServiceState,
  singleServiceFeatureKey,
  singleServiceReducer
} from './single-service/single-service.reducer';

export interface IState {
  [widgetFeatureKey]: IWidgetState,
  [profileFeatureKey]: IProfileState,
  [loaderFeatureKey]: ILoaderState,
  [servicesFeatureKey]: IServicesState,
  [singleServiceFeatureKey]: ISingleServiceState,
  [callHistoryFeatureKey]: ICallHistoryState,
  [sessionFeatureKey]: ISessionState,
  [playAudioFeatureKey]: IPlayAudioState,
  [commonFeatureKey]: ICommonState,
  [paymentsFeatureKey]: IPaymentsState,
  [languagesFeatureKey]: ILanguagesState,
}

export const reducers: ActionReducerMap<IState> = {
  [widgetFeatureKey]: widgetReducer,
  [profileFeatureKey]: profileReducer,
  [loaderFeatureKey]: loaderReducer,
  [servicesFeatureKey]: servicesReducer,
  [singleServiceFeatureKey]: singleServiceReducer,
  [callHistoryFeatureKey]: callHistoryReducer,
  [sessionFeatureKey]: sessionReducer,
  [playAudioFeatureKey]: playAudioReducer,
  [commonFeatureKey]: commonReducer,
  [paymentsFeatureKey]: paymentsReducer,
  [languagesFeatureKey]: languagesReducer,
};

export const metaReducers: MetaReducer<IState>[] = !environment.production ? [] : [];
