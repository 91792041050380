<ng-container *ngrxLet="combined$; let combined">

  <app-preloader *ngIf="combined.loaderStatus"></app-preloader>

  <!--TODO-->
  <!--<div class="iFrame" *ngIf="frameState">{{"iFrame" | translate}}</div>-->

  <router-outlet *ngIf="!combined.isWidget || !combined.isFetchingWidget"></router-outlet>

  <audio controls preload="auto" class="audio-element" [volume]="0.1" #audioElement src="/assets/bell.mp3"></audio>

</ng-container>
