import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// Store
import {
  languagesActionsType,
  LoadLanguagesListErrorAction,
  LoadLanguagesListSuccessAction
} from './languages.actions';

// Services
import { CommonService } from '@services/common.service';

@Injectable()
export class LanguagesEffects {

  constructor(
    private actions$: Actions,
    private commonService: CommonService,
  ) {
  }

  initLanguagesState$ = createEffect(() => this.actions$.pipe(
    ofType(languagesActionsType.loadLanguagesList),
    switchMap(() => {
      return this.commonService.getLanguagesList()
        .pipe(
          map(({ languages }) => new LoadLanguagesListSuccessAction(languages)),
          catchError((error) => of(new LoadLanguagesListErrorAction(error)))
        );
    })
  ));
}
