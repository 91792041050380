import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// Store
import {
  commonActionsType, InitCommonStateAction, LoadPrivacyStateAction,
  LoadPrivacyStateErrorAction,
  LoadPrivacyStateSuccessAction, LoadTermsStateAction,
  LoadTermsStateErrorAction,
  LoadTermsStateSuccessAction
} from './common.actions';

// Services
import { CommonService } from '@services/common.service';

@Injectable()
export class CommonEffects  implements  OnInitEffects {

  constructor(
    private actions$: Actions,
    private store$: Store,
    private commonService: CommonService,
  ) {
  }

  ngrxOnInitEffects(): Action {
    return new InitCommonStateAction();
  }

  initCommonState$ = createEffect(() => this.actions$.pipe(
    ofType(commonActionsType.initCommonState),
    map(() => {
      this.store$.dispatch(new LoadPrivacyStateAction());
      return new LoadTermsStateAction();
    })
  ));

  loadPrivacyData$ = createEffect(() => this.actions$.pipe(
    ofType(commonActionsType.loadPrivacyState),
    switchMap(() => {
      return this.commonService.getPrivacyPolicy()
        .pipe(
          map((profileData) => new LoadPrivacyStateSuccessAction(profileData)),
          catchError((error) => of(new LoadPrivacyStateErrorAction(error)))
        );
    })
  ));

  loadTermsData$ = createEffect(() => this.actions$.pipe(
    ofType(commonActionsType.loadTermsState),
    switchMap(() => {
      return this.commonService.getTermsAndCondition()
        .pipe(
          map((profileData) => new LoadTermsStateSuccessAction(profileData)),
          catchError((error) => of(new LoadTermsStateErrorAction(error)))
        );
    })
  ));
}
