import { Action } from '@ngrx/store';

import { ICardItem, ICards } from '@interfaces/user.interface';

export enum paymentsActionsType {
  clearPaymentCards = '[Payments] Clear payments cards',
  loadPaymentCards = '[Payments] Load payments cards',
  loadPaymentCardsSuccess = '[Payments] Load payments cards success',
  loadPaymentCardsError = '[Payments] Load payments cards error',
  addNewCard = '[Payments] Add new card',
  updateSelectedCard = '[Payments] Update selected card',
  deleteCard = '[Payments] Delete card',
  deleteCardSuccess = '[Payments] Delete card success',
  deleteCardError = '[Payments] Delete card error',
}

export class LoadPaymentCardsAction implements Action {
  readonly type = paymentsActionsType.loadPaymentCards;
}

export class LoadPaymentCardsSuccessAction implements Action {
  readonly type = paymentsActionsType.loadPaymentCardsSuccess;

  constructor(public cards: ICards) {
  }
}

export class LoadPaymentCardsErrorAction implements Action {
  readonly type = paymentsActionsType.loadPaymentCardsError;

  constructor(public error: any) {
  }
}

export class AddNewCardAction implements Action {
  readonly type = paymentsActionsType.addNewCard;

  constructor(public card: ICardItem) {
  }
}

export class UpdateSelectedCardAction implements Action {
  readonly type = paymentsActionsType.updateSelectedCard;

  constructor(public cardId: string) {
  }
}

export class DeleteCardAction implements Action {
  readonly type = paymentsActionsType.deleteCard;

  constructor(public cardId: string) {
  }
}

export class DeleteCardSuccessAction implements Action {
  readonly type = paymentsActionsType.deleteCardSuccess;

  constructor(public cardId: string) {
  }
}

export class DeleteCardErrorAction implements Action {
  readonly type = paymentsActionsType.deleteCardError;

  constructor(public error: any) {
  }
}

export class ClearPaymentCardsAction implements Action {
  readonly type = paymentsActionsType.clearPaymentCards;
}

export type PaymentsActions = LoadPaymentCardsAction |
  LoadPaymentCardsSuccessAction |
  LoadPaymentCardsErrorAction |
  AddNewCardAction |
  UpdateSelectedCardAction |
  DeleteCardAction |
  DeleteCardSuccessAction |
  DeleteCardErrorAction | ClearPaymentCardsAction;
