import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { CommonTermAndPolicyModel } from "@interfaces/user.interface";
import { ILanguage } from '@interfaces/languages.interfaces';

@Injectable()
export class CommonService {

  constructor(private http: HttpClient) {}

  public getTermsAndCondition(): Observable<CommonTermAndPolicyModel> {
    return this.http.get<CommonTermAndPolicyModel>(`/static-pages/terms-and-conditions`);
  }

  public getPrivacyPolicy(): Observable<CommonTermAndPolicyModel> {
    return this.http.get<CommonTermAndPolicyModel>(`/static-pages/privacy-policy`);
  }

  public getLanguagesList(): Observable<{ languages: ILanguage[] }> {
    return this.http.get<{ languages: ILanguage[] }>(`/languages`);
  }
}
