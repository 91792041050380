import { Injectable } from '@angular/core';

import { propertiesTheme } from '@services/theme/theme.properties';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private propertiesTheme = propertiesTheme;
  // private propertiesEnabled = ['primary_light', 'primary_brand', 'primary_dark', 'logo', 'accent_success', 'accent_error', 'primary_color', 'secondary_color'];
  private propertiesEnabled = ['primary_color', 'secondary_color', 'logo'];

  setDefaultTheme(): void {
    for (const key in this.propertiesTheme) {
      if (key === '--primary_light') {
        const opacityColor = this.getHexColor(this.propertiesTheme[key], true);
        document.documentElement.style.setProperty(key, opacityColor);
        continue;
      }
      document.documentElement.style.setProperty(key, this.propertiesTheme[key]);
    }
  }

  setCustomTheme(themeData: any): void {
    for (const key in themeData) {
      if (!this.propertiesEnabled.includes(key)) continue;
      if (key === 'primary_color') {
        document.documentElement.style.setProperty('--primary_brand', themeData[key]);
        continue;
      }
      if (key === 'secondary_color') {
        const opacityColor = this.getHexColor(themeData[key], true);
        document.documentElement.style.setProperty('--primary_light', opacityColor);
        continue;
      }
      if (key === 'logo') {
        document.documentElement.style.setProperty('--' + key, `url(${ themeData[key] })`);
        continue;
      }
      document.documentElement.style.setProperty('--' + key, themeData[key]);
    }
    document.documentElement.style.setProperty('--link-color', themeData['primary_color']);
  }

  getHexColor(hexCode: string, isOpacity: boolean): string {
    let hex;
    if (hexCode) {
      hex = hexCode.replace('#', '');
    } else {
      hex = isOpacity ? '2060F6' : 'EF7F1A';
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${ r },${ g },${ b }, ${ isOpacity ? '0.1' : '0.15' })`;
  }
}
