import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { catchError, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, throwError } from "rxjs";

// Service
import { WidgetsService } from "@services/widgets.service";

// Helpers
import { getQuery } from "@helpers/services-helper";

// Store
import { LoadProfileDataAction } from '../reducers/profile/profile.actions';
import { LoadPaymentCardsAction } from '../reducers/payments/payments.actions';
import { LoadCallHistoryAction } from '../reducers/call-history/call-history.actions';

// Models
import { IAuthResp, IMagicResp, IUDIDResp, IUserResp } from "@interfaces/user.interface";
import { STORAGE_ITEMS, TEMPORARY } from "@consts/storage";
import { LoadLanguagesListAction } from '../reducers/languages/languages.actions';

const USER = '/users/';
const TEMP_USER = '/temporary/'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public isFirstAccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private store: Store,
    private widgetsService: WidgetsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isFirstAccess$.next(!!this.activatedRoute.snapshot.queryParamMap.get('first_login'));
  }

  public saveToken(token): void {
    localStorage.setItem('token', 'Bearer ' + token);
  }

  public saveUser(response) {
    localStorage.setItem(STORAGE_ITEMS.USER, JSON.stringify(response));
  }

  public getUser(): IAuthResp {
    const user = localStorage.getItem(STORAGE_ITEMS.USER);
    try {
      return JSON.parse(user || '{}');
    } catch (e) {
      return {} as IAuthResp;
    }
  }

  public login(data, user): Observable<IAuthResp> {
    if (user != TEMPORARY) {
      const isWidget = this.widgetsService.getWidgetStatus();
      const widgetData = isWidget ? { ...data, widget_code: this.widgetsService.widgetId } : data;
      return this.http
        .post<IAuthResp>(`${ USER }login`, widgetData)
        .pipe(
          catchError(error => throwError(error)),
          tap(data => {
            localStorage.removeItem('deeplink_token');
            this.saveUser(data);
            localStorage.setItem(STORAGE_ITEMS.USER_ID, data.user_id.toString());
            this.store.dispatch(new LoadProfileDataAction());
            this.store.dispatch(new LoadCallHistoryAction(0, false));
            this.store.dispatch(new LoadPaymentCardsAction());
            this.store.dispatch(new LoadLanguagesListAction());
            this.isAuthUser$.next(true);
          })
        )
    }
    return this.http
      .post<any>(`${ TEMP_USER }sign-up`, { device_UDID: data })
      .pipe(
        catchError(error => throwError(error)),
        tap(data => {
          localStorage.removeItem('deeplink_token');
          localStorage.setItem(STORAGE_ITEMS.USER_ID, data.user_id.toString());
        })
      );
  }

  loginByEmail(data: { email: string }): Observable<IAuthResp> {
    const isWidget = this.widgetsService.getWidgetStatus();
    const widgetData = isWidget ? { ...data, widget_code: this.widgetsService.widgetId } : data;
    return this.http
      .post<IAuthResp>(`${ USER }login-by-email`, widgetData);
  }

  loginByPhone(data: { phone: string }): Observable<IAuthResp> {
    const isWidget = this.widgetsService.getWidgetStatus();
    const widgetData = isWidget ? { ...data, widget_code: this.widgetsService.widgetId } : data;
    return this.http
      .post<IAuthResp>(`${ USER }login-by-phone`, widgetData);
  }

  public register(data): Observable<IAuthResp> {
    return this.http.post<IAuthResp>(`${ USER }sign-up`, data);
  }

  public magicLogin(data): Observable<IMagicResp> {
    return this.http.get<IMagicResp>(`${ USER }magic-link?${ getQuery(data) }`);
  }

  public resendEmail(data): Observable<IUserResp> {
    return this.http.patch<IUserResp>(`${ USER }resend-activation-email`, data);
  }

  public forgotPass(data): Observable<IUserResp> {
    return this.http.post<IUserResp>(`${ USER }forgot-password`, data);
  }

  public resendForgotPasswordEmail(data): Observable<IUserResp> {
    return this.http.post<IUserResp>(`${ USER }resend-forgot-password-email`, { email: data });
  }

  public updateUDID(data): Observable<IUDIDResp> {
    return this.http.put<IUDIDResp>(`${ USER }update-udid`, data);
  }

  public getProfile(): Observable<IAuthResp> {
    return this.http.get<IAuthResp>(`${ USER }profile`)
      .pipe(
        tap(resp => {
          this.saveUser(resp)
        }));
  }

  public getTemporaryProfile(): Observable<IAuthResp> {
    return this.http.get<IAuthResp>(`${ TEMP_USER }profile`)
      .pipe(
        tap(resp => {
          this.saveUser(resp)
        }));
  }

  public updateProfile(data): Observable<IAuthResp> {
    return this.http.put<IAuthResp>(`${ USER }update-profile`, data);
  }

  public updateProfileLanguage(language: string): Observable<IAuthResp> {
    return this.http.put<IAuthResp>(`${ USER }update-profile-language`, { language });
  }

  public updateProfileTemporaryLanguage(language: string): Observable<IAuthResp> {
    return this.http.put<IAuthResp>(`${ TEMP_USER }update-profile-language`, { language });
  }

  public updateProfilePhoto(data): Observable<IUDIDResp> {
    return this.http.post<IUDIDResp>(`${ USER }update-profile-photo`, data);
  }

  public deleteProfile(password: string): Observable<{ message: string, status: string }> {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('_method', 'delete');
    return this.http.post<{ message: string, status: string }>(`${ USER }delete-profile`, formData);
  }

  public deleteProfilePhoto(): Observable<IUserResp> {
    return this.http.delete<IUserResp>(`${ USER }delete-profile-photo`);
  }

  public logoutUser(): Observable<IUserResp> {
    return this.http.post<IUserResp>(`${ USER }logout`, {})
      .pipe(
        catchError(error => throwError(error)),
        tap(() => {
          localStorage.removeItem('deeplink_token');
          this.isAuthUser$.next(false);
        })
      );
  }
}
