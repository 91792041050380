export enum NotificationTypes {
  ERROR = 'error',
  WARNING = 'warning',
  ATTENTION = 'attention',
  SUCCESS = 'success',
}

export enum INTERNET_CONNECTION {
  LOW = 'Low',
  MEDIUM_LOW = 'Medium-Low',
  MEDIUM = 'Medium',
  GOOD = 'Good',
  NO_CONNECTION = 'No-Connection'
}

export enum EWidgetTypeEntrance {
  Token = 'deeplink',
  // Token = 'token',
  Email = 'email'
}

export enum EAppStatus {
  Widgets = 'widgets'
}

export enum EElementLocalNameType {
  Button = 'button',
  MatIcon = 'mat-icon',
  Input = 'input',
  Div = 'div',
  Link = 'a'
}

export enum ECategory {
  Electrical = 'Electrical',
  Decorating = 'Decorating',
  Gas = 'Gas',
  HVAC = 'HVAC',
  Handyman = 'Handyman',
  Heating = 'Heating',
  Painting = 'Painting',
  Plumbing = 'Plumbing',
  Roofing = 'Roofing',
  Support = 'Support'
}

export interface IIconData {
  icon: string;
  icon_svg: string;
  id: number;
  name: string;
}

export interface ICheckInternetConnectionData {
  status: string;
  speed: number;
}

export const CLocalStorageDefaultKeys = [
  'user_id',
  'prev_call_id',
  'prev_category',
  'curr_category',
  'role',
  'temporary_token',
  'device_UDID',
  'temp_token',
  'photo',
  'token',
  'user',
  'country',
  'iCards',
  'tenant-email',
  'worker_id',
  'curr_worker_id',
  'workerData'
];

export const CSpeedConnectionParameters = {
  iterations: 1,
  file: {
    path: `https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/500kb.jpg`,
    size: 408949,
    shouldBustCache: true
  },
  retryDelay: 1500
};
